import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import './styles/HouseSale.css';

// Function to render stars based on the rating
const renderStars = (rating) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <>
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`}>&#9733;</span> // Filled star
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`}>&#9734;</span> // Empty star
      ))}
    </>
  );
};

const HouseSale = () => {

  useEffect(() => {
    document.title = 'Nzubo | Property for Sale';
  }, []);

  const [homes, setHomes] = useState([]);
  const [error, setError] = useState(null);

  // Search filter states
  const [locationFilter, setLocationFilter] = useState('');
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');

  // Fetch private homes from the API using axios https://khah-184f534d3b81.herokuapp.com
  useEffect(() => {
    axios
      // .get('http://localhost:3004/api/nyumbasales')
      .get('https://khah-184f534d3b81.herokuapp.com/api/nyumbasales')
      .then((response) => {
        setHomes(response.data);
        console.log(response.data)
      })
      .catch((err) => {
        setError('Failed to fetch homes. Please try again later, or check your network connectivity and refresh.');
        console.error(err);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter homes based on search criteria
  const filteredHomes = homes
    .filter((home) => !home.sold) // Only show homes that are not sold
    .filter((home) =>
      home.location.toLowerCase().includes(locationFilter.toLowerCase())
    )
    .filter((home) =>
      bedroomFilter ? home.numberOfBedrooms >= bedroomFilter : true
    )
    .filter((home) => (priceFilter ? home.price <= priceFilter : true));

  return (
    <div className="private-bnb-container">
      <div className="private-bnb-container-heading">
        <h3>Homes for Sale</h3>
        <p>Browse through locally listed properties and find your future home.</p>
      </div>


      {/* Search Filters */}
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Bedrooms"
          value={bedroomFilter}
          onChange={(e) => setBedroomFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price"
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        />
      </div>

      {/* Button to create a new private home */}
      <div className="create-new-home-container">
        <Link to="/nyumbasale/create" className="create-new-home-button">
          + Post House for Sale
        </Link>
      </div>

      {filteredHomes.length > 0 ? (
        <div className="private-stays-list">
          {filteredHomes.map((home) => (
            <NavLink to={`/nyumbasales/${home._id}`} key={home._id} className="stay-card">
              <img
                src={home.images[0] || '/default-image.jpg'}
                alt={home.description}
                className="stay-image"
              />
              <h4>{home.location}</h4>
              <p><strong>Bedrooms:</strong> {home.numberOfBedrooms}</p>
              <p><strong>Price:</strong> ${home.price}</p>
              <p><strong>Rating:</strong> {renderStars(home.rating)}</p>
            </NavLink>
          ))}
        </div>
      ) : (
        <p className="no-bnb-heading">
          No suitable private homes match your search criteria? Please try adjusting the filters.
        </p>
      )}
    </div>
  );
};

export default HouseSale;
