import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import './styles/HouseRent.css';

// Function to render stars based on the rating
// Function to render stars based on the rating
const renderStars = (rating) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <span className="rating-stars">
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`}>&#9733;</span> // Filled star
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`}>&#9734;</span> // Empty star
      ))}
    </span>
  );
};


const HouseRent = () => {
  useEffect(() => {
    document.title = 'Nzubo | Property for Rent';
  }, []);

  const [houses, setHouses] = useState([]);
  const [error, setError] = useState(null);

  // Search filter states
  const [locationFilter, setLocationFilter] = useState('');
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');

  // Fetch rental houses from the API
  useEffect(() => {
    axios
      .get('https://khah-184f534d3b81.herokuapp.com/api/nyumbasp')
      .then((response) => {
        setHouses(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        setError('Failed to fetch houses for rent. Please try again later.');
        console.error(err);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter houses based on search criteria
  const filteredHouses = houses
    .filter((house) => !house.onRent) // Only show houses available for rent
    .filter((house) =>
      house.location.toLowerCase().includes(locationFilter.toLowerCase())
    )
    .filter((house) =>
      bedroomFilter ? house.numberOfBedrooms >= bedroomFilter : true
    )
    .filter((house) => (priceFilter ? house.price <= priceFilter : true));

  return (
    <div className="house-rent-container">
      <div className="house-rent-heading">
        <h4>Houses for Rent</h4>
        <p>Explore houses available for rent and find the perfect place for your stay.</p>
      </div>

      {/* Search Filters */}
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Bedrooms"
          value={bedroomFilter}
          onChange={(e) => setBedroomFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price"
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        />
      </div>

      {/* Button to create a new rental listing */}
      <div className="create-new-rent-container">
        <Link to="/nyumbasp/create" className="create-new-rent-button">
          + Post House for Rent
        </Link>
      </div>

      {filteredHouses.length > 0 ? (
        <div className="rental-houses-list">
          {filteredHouses.map((house) => (
            <NavLink to={`/nyumbasp/${house._id}`} key={house._id} className="rent-card">
              <img
                src={house.images[0] || '/default-image.jpg'}
                alt={house.description}
                className="rent-image"
              />
              <h4>{house.location}</h4>
              <p><strong>Bedrooms:</strong> {house.numberOfBedrooms}</p>
              <p><strong>Price:</strong> ${house.price} / month</p>
              <p><strong>Rating:</strong> {renderStars(house.rating)}</p>
            </NavLink>
          ))}
        </div>
      ) : (
        <p className="no-rent-heading">
          No houses available matching your criteria. Please try adjusting the filters.
        </p>
      )}
    </div>
  );
};

export default HouseRent;
